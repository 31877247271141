import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import get from 'lodash.get';

import './index.scss';

import { Section, Container, Row, Column, Image } from 'components/atoms';

const WidgetWorkImage = ({ data, className }) => {
  const { media, background_fill, background_color, size, same_color } = data;
  const { source_url: img, alt_text, title, media_details } = media || {};

  const { width, height } = media_details;

  const placeholder_base64 = get(media, 'acf.placeholder_base64');

  const isFullscreen = size === 'fullscreen';
  const isMedium = size === 'medium';

  const containerSize = isMedium ? 'col-10 offset-1' : 'col-12';

  const backgroundColorStyle = background_fill
    ? {
        backgroundColor: `${background_color}`,
      }
    : {};

  const image = (
    <Image
      className={cn('widget-work-image__picture', {
        [`widget-work-image__picture_${size}`]: size,
      })}
      src={img}
      placeholder64={placeholder_base64}
      lazyLoading
      alt={alt_text}
      title={title}
      imageAspect={width / height}
      imageFit={isFullscreen || isMedium ? 'cover' : 'fill'}
      image={media_details}
    />
  );

  return (
    <Section
      className={cn(
        'widget-work-image',
        { 'widget-work-image_fullscreen': isFullscreen },
        { 'widget-work-offset-bg_fill': background_fill },
        { 'widget-work-offset-size_fullscreen': isFullscreen },
        { 'widget-work-offset-bg_same-color': same_color },
        [className]
      )}
      style={backgroundColorStyle}
    >
      <Container type={isFullscreen ? 'fluid' : 'default'}>
        <Row>
          {isFullscreen ? (
            <div
              className="widget-work-image__container"
              onMouseDown={e => e.preventDefault()}
              onContextMenu={e => e.preventDefault()}
              role="button"
              tabIndex="0"
            >
              {img && image}
            </div>
          ) : (
            <Column
              col=""
              className={cn([containerSize])}
              onMouseDown={e => e.preventDefault()}
              onContextMenu={e => e.preventDefault()}
            >
              <div className="widget-work-image__container">{img && image}</div>
            </Column>
          )}
        </Row>
      </Container>
    </Section>
  );
};

WidgetWorkImage.defaultProps = {
  className: '',
};

WidgetWorkImage.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default WidgetWorkImage;
